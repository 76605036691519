import React, { ReactNode } from 'react'
import { ENGLISH_TITLE } from '../../data/English'
import { MvChildPage } from '../Mv/MvChildPage'
import { LayoutRoot } from './LayoutRoot'

interface Props {
  children: ReactNode
}

/**
 * ニュース一覧のレイアウト
 * @param {ReactNode} children
 */
/** */
export const LayoutNewsTop = ({ children }: Props) => {
  return (
    <LayoutRoot>
      <MvChildPage
        heading={'ニュース'}
        headingEnglish={ENGLISH_TITLE.News}
        BreadcrumbData={[{ title: 'ニュース', url: '' }]}
      />
      {children}
    </LayoutRoot>
  )
}
